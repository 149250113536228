import {allEmployeesRegisterConfig} from "@/components/register/RegisterConfigs";
import {loadData} from "@/modules/CommonUtils";

export async function generateEmployeesRegisterConfig() {
    const regConfig = { tabs: [] }

    regConfig.tabs.push({
        title: 'Все',
        config: allEmployeesRegisterConfig
    })
    let ourOrgs = await loadData('/supmain/legalentities?search=isOurOrg:true', true)
    for (let org of ourOrgs) {
        if(org.shortName.startsWith('ИП') || org.shortName === 'Эксперты ГК НИСИПП'){
            continue
        }
        let config = JSON.parse(JSON.stringify(allEmployeesRegisterConfig))
        config.customFilterFunction = (entry) => {
            console.log(entry)
            if (entry.jobs == null)
                return null
            for (let j of entry.jobs) {
                if (j.organization && j.organization.id === org.id)
                    return entry
            }
            return null
        }
        regConfig.tabs.push({
            title: org.shortName != null && org.shortName.length > 0 ? org.shortName : org.fullName,
            config: config
        })
    }
    let config = JSON.parse(JSON.stringify(allEmployeesRegisterConfig))
    config.customFilterFunction = (entry) => {
        if (!entry.inState)
            return entry
        return null
    }
    regConfig.tabs.push({
        title: 'Внештатные',
        config: config
    })
    return regConfig
}

export async function generateOfferEmployeesRegisterConfig() {
    const regConfig = { tabs: [] }
    let config = JSON.parse(JSON.stringify(allEmployeesRegisterConfig))
    config.customFilterFunction = (entry) => {
            console.log(entry)
            if(entry.isRoleOffer) return entry
            else return null
        }
        regConfig.tabs.push({
            title: 'Ответственные за содержательную часть',
            config: config
        })
    return regConfig
}

export async function generateFormalEmployeesRegisterConfig() {
    const regConfig = { tabs: [] }
    let config = JSON.parse(JSON.stringify(allEmployeesRegisterConfig))
    config.customFilterFunction = (entry) => {
        console.log(entry)
        if(entry.isRoleFormal) return entry
        else return null
    }
    regConfig.tabs.push({
        title: 'Ответственные за формальную часть',
        config: config
    })
    return regConfig
}
