import {
  clientTypeDict,
  customerLevelsDict,
  regionsDict,
  legalEntityTypeDict,
  applicationStatusDict,
  riskAssessmentDict,
  contractStatusDict,
  projectStatusDict,
  supMarketDict,
  regionsDict223,
  ourOrgsAccountsDict,
} from '../../modules/NSI';

const defaultPageSize = 20;

///////////////////////////
// Конфиг для раздела "Сотрудники"
export const allEmployeesRegisterConfig = {
  apiRestEndpoint: '/supmain/experts',
  entryComponent: 'EmployeeEntry',
  pageSize: 10000,
  filters: [
    { title: 'Фамилия', path: 'surname', type: 'STRING' },
    { title: 'Имя', path: 'name', type: 'STRING' },
    { title: 'Отчество', path: 'middleName', type: 'STRING' },
    { title: 'Образование', path: 'education', type: 'STRING' },
    { title: 'Ученая степень', path: 'degree', type: 'STRING' },
    { title: 'Специальность', path: 'specialty', type: 'STRING' },
    { title: 'Квалификация', path: 'qualification', type: 'STRING' },
  ],
  sortFields: [
    { title: 'фамилии', path: 'surname' }
  ],
  defaultSortField: 'id',
};

///////////////////////////
// Конфиг для раздела "Контрагенты"

const counterpartiesFilters = [
  { title: 'Полное наименование', path: 'fullName', type: 'STRING' },
  { title: 'Сокращенное наименование', path: 'shortName', type: 'STRING' },
  { title: 'ИНН', path: 'inn', type: 'STRING' },
  {
    title: 'Тип контрагента',
    path: 'type',
    type: 'DICT',
    dict: legalEntityTypeDict,
  },
  {
    title: 'Тип клиента',
    path: 'clientType',
    type: 'DICT',
    dict: clientTypeDict,
  },
  { title: 'Субъект РФ', path: 'region.id', type: 'DICT', dict: regionsDict },
  { title: 'Уровень', path: 'level', type: 'DICT', dict: customerLevelsDict },
];

const counterpartiesSortFields = [
    { title: 'Полное наименование', path: 'fullName' },
    { title: 'Сокращенное наименование', path: 'shortName' },
    { title: 'Субъект РФ', path: 'region.regionName' },
]

export const allCounterpartiesRegisterConfig = {
  apiRestEndpoint: '/supmain/legalentities',
  entryComponent: 'CounterpartyEntry',
  pageSize: defaultPageSize,
  filters: counterpartiesFilters,
  sortFields: counterpartiesSortFields,
  defaultSortField: 'id',
};

export const ourCounterpartiesRegisterConfig = {
  apiRestEndpoint: '/supmain/legalentities',
  apiConfigPresets: 'isOurOrg:true',
  entryComponent: 'CounterpartyEntry',
  pageSize: defaultPageSize,
  filters: counterpartiesFilters,
  sortFields: counterpartiesSortFields,
  defaultSortField: 'id',
};

export const problematicCounterpartiesRegisterConfig = {
  apiRestEndpoint: '/supmain/legalentities',
  apiConfigPresets: 'problematic:true',
  entryComponent: 'CounterpartyEntry',
  pageSize: defaultPageSize,
  filters: counterpartiesFilters,
  sortFields: counterpartiesSortFields,
  defaultSortField: 'id',
};

export const customerCounterpartiesRegisterConfig = {
  apiRestEndpoint: '/supmain/legalentities',
  apiConfigPresets: 'customerContracts$true,isOurOrg:nullfalse',
  entryComponent: 'CounterpartyEntry',
  pageSize: defaultPageSize,
  filters: counterpartiesFilters,
  sortFields: counterpartiesSortFields,
  defaultSortField: 'id',
};

export const supplierCounterpartiesRegisterConfig = {
  apiRestEndpoint: '/supmain/legalentities',
  apiConfigPresets: 'supplierContracts$true,isOurOrg:nullfalse',
  entryComponent: 'CounterpartyEntry',
  pageSize: defaultPageSize,
  filters: counterpartiesFilters,
  sortFields: counterpartiesSortFields,
  defaultSortField: 'id',
};

export const counterpartiesRegisterConfig = {
  tabs: [
    {
      title: 'Все организации',
      config: allCounterpartiesRegisterConfig,
    },
    {
      title: 'Заказчики',
      config: customerCounterpartiesRegisterConfig,
    },
    {
      title: 'Подрядчики',
      config: supplierCounterpartiesRegisterConfig,
    },
    {
      title: 'Наши организации',
      config: ourCounterpartiesRegisterConfig,
    },
    {
      title: 'Реестр проблемных заказчиков',
      config: problematicCounterpartiesRegisterConfig,
    },
  ],
};

///////////////////////////
// Конфиг для раздела "Тендеры"

const notificationSortFields = [
  { title: 'заказчику', path: 'customer.fullName' },
  { title: 'теме', path: 'theme' },
  { title: 'цене', path: 'maxPrice' },
  { title: 'дате вскрытия', path: 'endDateTime' },
  { title: 'дате публикации', path: 'publishDateTime' },
];

export const notificationsRegisterConfig = {
  apiRestEndpoint: '/supmain/notifications',
  entryComponent: 'NotificationEntry',
  pageSize: defaultPageSize,
  filters: [
    { title: 'Регистрационный номер', path: 'regnum', type: 'STRING' },
    { title: 'Заказчик', path: 'customer.fullName', type: 'STRING' },
    {
      title: 'Регион заказчика',
      path: 'customer.region.id',
      type: 'DICT',
      dict: regionsDict,
    },
    {
      title: 'Уровень заказчика',
      path: 'customer.level',
      type: 'DICT',
      dict: customerLevelsDict,
    },
    { title: 'Тема', path: 'theme', type: 'STRING' },
    { title: 'Сумма', path: 'maxPrice', type: 'NUMBER-RANGE' },
    { title: 'Дата объявления', path: 'publishDateTime', type: 'DATE-RANGE' },
    { title: 'Дата окончания подачи', path: 'endDateTime', type: 'DATE-RANGE' },
  ],
  sortFields: notificationSortFields,
  defaultSortField: 'id',
  defaultSortAsc: false
};

export const notifications44RegisterConfig = {
  apiRestEndpoint: '/notifications44',
  entryComponent: 'Notification44Entry',
  pageSize: defaultPageSize,
  filters: [
    { title: 'Регистрационный номер', path: 'regnum', type: 'STRING' },
    { title: 'Заказчик', path: 'customer.fullName', type: 'STRING' },
    {
      title: 'Регион заказчика',
      path: 'customer.region.id',
      type: 'DICT',
      dict: regionsDict,
    },
    {
      title: 'Уровень заказчика',
      path: 'customer.level',
      type: 'DICT',
      dict: customerLevelsDict,
    },
    { title: 'Тема', path: 'theme', type: 'STRING' },
    { title: 'Сумма', path: 'maxPrice', type: 'NUMBER-RANGE' },
    { title: 'Дата объявления', path: 'publishDateTime', type: 'DATE-RANGE' },
    { title: 'Дата окончания подачи', path: 'endDateTime', type: 'DATE-RANGE' },
  ],
  sortFields: notificationSortFields,
  defaultSortField: 'id',
  defaultSortAsc: false
};

export const notifications223RegisterConfig = {
  apiRestEndpoint: '/notifications223',
  entryComponent: 'Notification223Entry',
  pageSize: defaultPageSize,
  filters: [
    { title: 'Регистрационный номер', path: 'regnum', type: 'STRING' },
    { title: 'Заказчик', path: 'customer.fullName', type: 'STRING' },
    {
      title: 'Регион заказчика',
      path: 'customer.region.id',
      type: 'DICT',
      dict: regionsDict223,
    },
    { title: 'Тема', path: 'theme', type: 'STRING' },
    { title: 'Сумма', path: 'maxPrice', type: 'NUMBER-RANGE' },
    { title: 'Дата объявления', path: 'publishDateTime', type: 'DATE-RANGE' },
    { title: 'Дата окончания подачи', path: 'endDateTime', type: 'DATE-RANGE' },
  ],
  sortFields: notificationSortFields,
  defaultSortField: 'id',
  defaultSortAsc: false
};

export const applicationsRegisterConfig = {
  apiRestEndpoint: '/supmain/applications',
  entryComponent: 'ApplicationEntry',
  pageSize: defaultPageSize,
  filters: [
    {
      title: 'Регистрационный номер',
      path: 'notification.regnum',
      type: 'STRING',
    },
    {
      title: 'Заказчик',
      path: 'notification.customer.fullName',
      type: 'STRING',
    },
    {
      title: 'Регион заказчика',
      path: 'notification.customer.region.id',
      type: 'DICT',
      dict: regionsDict,
    },
    { title: 'Тема', path: 'notification.theme', type: 'STRING' },
    {
      title: 'Ответственный за содержание',
      path: 'offerExpert.id',
      type: 'REGISTER',
      regConfig: undefined,
      itemText: 'surname',
      itemValue: 'id',
    },
    {
      title: 'Ответственный за форм.часть',
      path: 'formalExpert.id',
      type: 'REGISTER',
      regConfig: undefined,
      itemText: 'surname',
      itemValue: 'id',
    },

    {
      title: 'Статус',
      path: 'applicationStatus',
      type: 'DICT',
      dict: applicationStatusDict,
    },
    {
      title: 'Дата вскрытия',
      path: 'notification.endDateTime',
      type: 'DATE-RANGE',
    },
    {
      title: 'Оценка риска',
      path: 'riskAssessment',
      type: 'DICT',
      dict: riskAssessmentDict,
    },
    { title: 'Только приоритетные', path: 'highPriority', type: 'CHECKBOX' },
  ],
  sortFields: [
    { title: 'отв. за содержание', path: 'offerExpert.surname' },
    { title: 'отв. за формальную часть', path: 'formalExpert.surname' },
    { title: 'заказчику', path: 'notification.customer.fullName' },
    { title: 'теме', path: 'notification.theme' },
    { title: 'цене', path: 'notification.maxPrice' },
    { title: 'дате вскрытия', path: 'notification.endDateTime' },
    { title: 'дате публикации', path: 'notification.publishDateTime' },
  ],
  defaultSortField: 'id',
  defaultSortAsc: false
};

///////////////////////////
// Конфиг для раздела "Договоры"

const contractsSharedRegisterConfig = {
  apiRestEndpoint: '/supmain/contracts',
  entryComponent: 'ContractEntry',
  pageSize: defaultPageSize,
  filters: [
    { title: 'Номер', path: 'contractNumber', type: 'STRING' },
    { title: 'Шифр', path: 'cypher', type: 'STRING' },
    { title: 'Тема', path: 'theme', type: 'STRING' },
    {
      title: 'Статус',
      path: 'contractStatus',
      type: 'DICT',
      dict: contractStatusDict,
    },
    {
      title: 'Заказчик',
      path: 'customer.id',
      type: 'REGISTER',
      regConfig: counterpartiesRegisterConfig,
      itemText: 'fullName',
      itemValue: 'id',
    },
    {
      title: 'Исполнитель',
      path: 'supplier.id',
      type: 'REGISTER',
      regConfig: counterpartiesRegisterConfig,
      itemText: 'fullName',
      itemValue: 'id',
    },
    {
      title: 'Руководитель проекта',
      path: 'offerExpert.id',
      type: 'REGISTER',
      regConfig: undefined,
      itemText: 'surname',
      itemValue: 'id',
    },
    {
      title: 'Ответственный за ведение',
      path: 'formalExpert.id',
      type: 'REGISTER',
      regConfig: undefined,
      itemText: 'surname',
      itemValue: 'id',
    },
    { title: 'Сумма', path: 'contractSum', type: 'INTEGER-RANGE' },
    { title: 'Дата начала', path: 'startDate', type: 'DATE-RANGE' },
    { title: 'Дата окончания', path: 'endDate', type: 'DATE-RANGE' },
  ],
  sortFields: [
    { title: 'шифру', path: 'cypher' },
    { title: 'номеру', path: 'contractNumber' },
    { title: 'дате начала', path: 'startDate' },
    { title: 'дате окончания', path: 'endDate' },
    { title: 'заказчику', path: 'customer.fullName' },
    { title: 'исполнителю', path: 'supplier.fullName' },
    { title: 'теме', path: 'theme' },
    { title: 'сумме', path: 'contractSum' },
  ],
  defaultSortField: 'id',
  defaultSortAsc: false
};

export const customerContractsRegisterConfig = Object.assign(
  { apiConfigPresets: 'supplier.isOurOrg:true' },
  contractsSharedRegisterConfig
);
export const supplierContractsRegisterConfig = Object.assign(
  { apiConfigPresets: 'customer.isOurOrg:true' },
  contractsSharedRegisterConfig
);

export const projectsRegisterConfig = {
  apiRestEndpoint: '/supmain/projects/byYear/',
  filters: [
    { title: 'Название', path: 'name', type: 'STRING' },
    { title: 'Статус', path: 'status', type: 'DICT', dict: projectStatusDict },
    {
      title: 'Руководитель',
      path: 'offerExpert.id',
      type: 'DICT',
      dict: undefined,
    },
    { title: 'Направление', path: 'market', type: 'DICT', dict: supMarketDict },
  ],
  sortFields: [
    { title: 'номеру', path: 'number' },
    { title: 'названию', path: 'name' },
    { title: 'статусу', path: 'status' },
    { title: 'руководителю', path: 'offerExpert.fullName' },
  ],
  defaultSortField: 'id',
};

///////////////////////////
// Конфиг для раздела "Тендеры"
export const tendersRegistersConfig = {
  tabs: [
    {
      title: 'Мониторинг',
      config: notificationsRegisterConfig,
    },
    {
      title: 'Закупки 44-ФЗ',
      config: notifications44RegisterConfig,
    },
    {
      title: 'Закупки 223-ФЗ',
      config: notifications223RegisterConfig,
    },
  ],
};

export const entriesRegisterConfig = {
  apiRestEndpoint: '/supmain/wires/byYear/',
  entryComponent: 'WRegisterEntry',
  filters: [
    { title: 'ID проводки', path: 'id', type: 'STRING' },
    {
      title: 'Организация',
      path: 'data.organization.id',
      type: 'DICT',
      dict: ourOrgsAccountsDict,
    },
    {
      title: 'План/факт',
      path: 'data.wiringType',
      type: 'DICT',
      dict: [
        { title: 'План', code: 1 },
        { title: 'Факт', code: 2 },
      ],
    },
    {
      title: 'Нал/Безнал',
      path: 'data.paymentMethod.id',
      type: 'DICT',
      dict: [
        { title: 'Нал', code: 1 },
        { title: 'Безнал', code: 2 },
      ],
    },
    {
      title: 'Ответственный',
      path: 'data.expert.id',
      type: 'DICT',
      dict: undefined,
    },
    {
      title: 'Контрагент',
      path: 'data.contractor.id',
      type: 'REGISTER',
      regConfig: counterpartiesRegisterConfig,
      itemText: 'fullName',
      itemValue: 'id',
    },
    {
      title: 'Проект',
      path: 'data.project.id',
      type: 'DICT',
      dict: undefined,
    },
    {
      title: 'Договор',
      path: 'data.contractId.id',
      type: 'DICT',
      dict: undefined,
    },
    {
      title: 'Год по бюджету',
      path: 'data.budgetYear',
      type: 'DICT',
      dict: [2022, 2023, 2024],
      itemValue: 'budgetYear',
    },
    {
      title: 'Направление',
      path: 'data.wiringTypeDir',
      type: 'DICT',
      dict: [
        { code: 'expences', title: 'Расход' },
        { code: 'income', title: 'Доход' },
        {
          code: 'internalRedistribution',
          title: 'Внутреннее перераспределение',
        },
        { code: 'securityDeposit', title: 'Обеспечение' },
        { code: 'externalRedistribution', title: 'Внешнее перераспределение' },
        { code: 'reserve', title: 'Резерв' },
      ],
    },
    {
      title: 'Фонд',
      path: 'data.funds.id',
      type: 'DICT',
      dict: [2022, 2023, 2024],
    },
    {
      title: 'Статья',
      path: 'data.article.id',
      type: 'DICT',
      dict: [2022, 2023, 2024],
    },

    { title: 'Сумма', path: 'data.sum', type: 'NUMBER-RANGE' },
    // { title: 'Дата начала', path: 'publishDateTime', type: 'DATE-RANGE' },
    // { title: 'Дата завершения', path: 'endDateTime', type: 'DATE-RANGE' },
  ],
  sortFields: [
    { title: 'номеру', path: 'id' },
    { title: 'названию', path: 'name' },
    { title: 'статусу', path: 'status' },
    { title: 'руководителю', path: 'offerExpert.fullName' },
  ],
  defaultSortField: 'id',
};

export const entries1cRegisterConfig = {
  apiRestEndpoint: '/supmain/projects/byYear/',
  filters: [
    { title: 'Наименование файла', path: 'fileName', type: 'STRING' },
    { title: 'Статус', path: 'status', type: 'DICT', dict: undefined },
    { title: 'Автор', path: 'author', type: 'DICT', dict: undefined },
    { title: 'Дата загрузки', path: 'publishDateTime', type: 'DATE-RANGE' },
  ],
  sortFields: [
    { title: 'номеру', path: 'number' },
    { title: 'названию', path: 'name' },
    { title: 'статусу', path: 'status' },
    { title: 'руководителю', path: 'offerExpert.fullName' },
  ],
  defaultSortField: 'id',
};

//notifications44RegisterConfig.filters[8].regConfig = tendersRegistersConfig
