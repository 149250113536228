<template>
  <div class="register-dialog">
    <v-autocomplete outlined :label="fieldTitle" clearable @click:clear="clearSelectedFilter(); items = []"
      :items="items" :item-text="textPropSymbol" :search-input.sync="search" hide-no-data
      @focusout="focusOut()" ref="autocomplete"
      return-object @change="setSelectedFilter($event)" :value="valueObject"
      :rules="notNull ? [
                  (v=>(v != null) || 'Выберите значение из списка'),
              ] : []"
    ></v-autocomplete>
    <!-- Про странное :value выше: если перешли по ссылке, то в параметрах только код, но нет названия сущности. Нужно вписать хотя бы идшник-->
    <register-select-dialog :title="dialogTitle" :reg-config="regConfig" :presets="presets" :presetActiveTab="presetActiveTab"
      @select="setSelectedFilter($event)"></register-select-dialog>
  </div>
</template>

<script>
import api from '@/modules/api'
import RegisterSelectDialog from "./RegisterSelectDialog";
import { debounce } from "@/modules/CommonUtils";

export default {
  name: "RegisterSelectField",
  props: ['fieldTitle', //название поля ввода
    'dialogTitle', //заголовок диалога выбора в реестре
    'regConfig', //конфигурация реестра
    'valueObject', //значение-объект для v-autocomplete
    'presets', //предустановленные фильтры (массив)
    'pageSize', //размер выпадающего списка
    'textFunction', //функция, принимающая аргументом item и выдающая текст, который отобразится в поле ввода
    'textSearchAttribute', //атрибут, по которому ищет v-autocomplete
    'defaultTab', //номер табы, по которой будет текстовый поиск v-autocomplete (берется только эндпоинт)
    'notNull', //если true, поле подсвечивается невалидным при значении null
    'presetActiveTab' //индекс табы, которая по умолчанию открывается
  ],
  components: { RegisterSelectDialog },
  data() {
    return {
      items: [],
      search: null,
      // при выборе записи из реестра получается зацикливание:
      // выбрали - изменилось значение v-autocomplete - теперь оно загружается из watcher -> query и т.д. Нужно это пропустить
      skipUpdate: false,
      textPropSymbol: "_register_select_field_title"//Symbol("Displayed text") //не работает с символами, поэтому просто имя свойства
    }
  },
  methods: {
    async getItems() {
      let query = {}
      query.size = this.pageSize
      query.search = this.textSearchAttribute + ':' + this.search
      let restEndPoint=''
      if(this.defaultTab != null)
        restEndPoint = this.regConfig.tabs[this.defaultTab].config.apiRestEndpoint
      else
        restEndPoint = this.regConfig.apiRestEndpoint
      this.items = (await api.get(restEndPoint, query)).payload
      this.setTitles()
      this.$forceUpdate();
      console.log(this.items)
    },

    setTitles() {
      console.log(this.items)
      for (let item of this.items) {
        item[this.textPropSymbol] = this.textFunction(item)
      }
    },

    //пользователь нажал на крестик ("очистить") у фильтра
    clearSelectedFilter() {
      this.registerFilterTextValues = null
      this.$emit('clear')
    },

    //Обработчик события выбора фильтра из реестра (например, склада-источника)
    //filter - метаданные фильтра, pickedEntry - выбранная запись
    setSelectedFilter(pickedEntry) {
      if(!pickedEntry) return this.clearSelectedFilter();
      this.skipUpdate = true //смотри комментарий при объявлении
      //console.log('pickedEntry', pickedEntry)
      if(pickedEntry != null) { //поле может быть null, если мы попали сюда по цепочке clearPickedFilter -> v-autocomplete changed -> setPickedFilter
        pickedEntry[this.textPropSymbol] = this.textFunction(pickedEntry)
        this.$emit('select', pickedEntry)
        this.items = [pickedEntry]
        this.$forceUpdate()
      }
    },

    async querySelections() {
      if (this.search.length >= 3) {
        this.getItemsDebounced()
      }
      if (this.search.length < 3) {
        this.items = [];
      }
    },

    focusOut(){
      if(this.valueObject && this.$refs.autocomplete.$refs.input.value !== this.textFunction(this.valueObject)){
        this.$emit('select', null)
        this.items = []
        this.$forceUpdate()
      }
    }

  },
  watch: {
    search(val) {
      if (this.skipUpdate)
        this.skipUpdate = false
      else
        val && val !== this.text && this.querySelections()
    }
  },
  beforeMount() {
    if(this.valueObject){
      this.skipUpdate = true
      this.valueObject[this.textPropSymbol] = this.textFunction(this.valueObject)
      this.items = [this.valueObject]
    }
    this.getItemsDebounced = debounce(this.getItems, 250)
  },
  beforeUpdate() {
    // if(this.valueObject){
    //   this.skipUpdate = true
    //   this.valueObject[this.textPropSymbol] = this.textFunction(this.valueObject)
    //   this.items = [this.valueObject]
    // }
  },
}
</script>

<style scoped>
.register-dialog {
  position: relative;
}
</style>
